import React from 'react';

function Developer() {
  return (
    <section id="developer" className="section">
      <div className="container">
        <h2 className="section-title">Developer Profile</h2>
        <div className="card">
          <h3>Technical Skills</h3>
          <div className="skills-list">
            <span className="skill-tag">JavaScript</span>
            <span className="skill-tag">React</span>
            <span className="skill-tag">Node.js</span>
            <span className="skill-tag">Python</span>
            <span className="skill-tag">Django</span>
            <span className="skill-tag">PostgreSQL</span>
            <span className="skill-tag">MongoDB</span>
            <span className="skill-tag">Docker</span>
            <span className="skill-tag">AWS</span>
            <span className="skill-tag">Git</span>
            <span className="skill-tag">Xamarin</span>
            <span className="skill-tag">iOS Development</span>
            <span className="skill-tag">Android Development</span>
            <span className="skill-tag">OPC UA</span>
            <span className="skill-tag">IoT</span>
            <span className="skill-tag">PHP</span>
            <span className="skill-tag">Laravel</span>
            <span className="skill-tag">WordPress</span>
          </div>
          <h3>Key Projects</h3>
          <div className="projects-list">
            <span className="project-tag">PLC Control iOS App</span>
            <span className="project-tag">Online Classifieds Platform</span>
            <span className="project-tag">Custom Native Mobile Applications</span>
            <span className="project-tag">E-commerce Websites</span>
          </div>
          <p>As a versatile full stack developer, I have experience across a wide range of technologies and platforms. My expertise spans from front-end development using modern JavaScript frameworks to back-end systems and mobile app development. I have a strong track record of delivering high-quality, scalable solutions for diverse clients and industries, including e-commerce, maritime technology, and online platforms. My approach focuses on writing clean, testable, and maintainable code, always adhering to best practices and design patterns.</p>
        </div>
      </div>
    </section>
  );
}

export default Developer;