import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="container">
        <p>2024 &copy; Ene Florian</p>
      </div>
    </footer>
  );
}

export default Footer;
