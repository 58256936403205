import React from 'react';
import { Link } from 'react-router-dom';

function BlogIntroduction() {
  return (
    <div className="blog-introduction">
      <h3>Welcome to my blog!</h3>
      <p>
        I am passionate about emerging technologies and have extensive experience in the IT field.
        I conduct research in Artificial Intelligence (AI) and have been involved in the crypto world for many years.
        As a Web3 developer, I have worked on numerous innovative blockchain projects.
      </p>
      <p>
        In this blog, I will share my knowledge and experiences in two main areas:
        AI and Blockchain. I hope you will find the information presented here useful and interesting.
      </p>
    </div>
  );
}

function BlogSection({ title, description, topics, link }) {
  return (
    <div className="blog-section">
      <h3>{title}</h3>
      <p>{description}</p>
      <ul>
        {topics.map((topic, index) => (
          <li key={index}>{topic}</li>
        ))}
      </ul>
      <Link to={link} className="btn">
        Read more about {title}
      </Link>
    </div>
  );
}

function Blog() {
  return (
    <section id="blog" className="section">
      <div className="container">
        <h2 className="section-title">Blog</h2>
        <div className="card">
          <BlogIntroduction />
          <div className="blog-content">
            <BlogSection 
              title="Artificial Intelligence (AI)"
              description="Here you will find articles about the latest developments in AI, including:"
              topics={[
                "Machine Learning",
                "Deep Learning",
                "Natural Language Processing",
                "Computer Vision",
                "AI applications in various industries",
                "AI and the future of work",
              ]}
              link="/blog/ai"
            />
            <BlogSection 
              title="Blockchain"
              description="Explore my articles about blockchain technology and cryptocurrencies:"
              topics={[
                "Blockchain fundamentals",
                "Web3 development",
                "Smart Contracts",
                "DeFi (Decentralized Finance)",
                "NFTs and their applications",
                "Crypto market analysis"
              ]}
              link="/blog/blockchain"
              className="btn"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;
