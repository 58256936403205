import React from 'react';

function AIBlog() {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="ai-blog section">
      <div className="container">
        <h2 className="section-title">Artificial Intelligence Blog</h2>
        <div className="card">
          <p className="blog-date">Published on: {currentDate}</p>
          <div className="blog-content">
            <h3>Exploring the Frontiers of Artificial Intelligence</h3>
            <p>
              Welcome to my AI blog, where we delve into the fascinating world of Artificial Intelligence 
              and its rapidly evolving landscape. In this space, we'll explore cutting-edge developments 
              across various AI domains, including:
            </p>
            <ul>
              <li><strong>Machine Learning:</strong> The backbone of AI, enabling systems to learn and improve from experience.</li>
              <li><strong>Deep Learning:</strong> Advanced neural networks mimicking the human brain's structure and function.</li>
              <li><strong>Natural Language Processing:</strong> Bridging the gap between human communication and machine understanding.</li>
              <li><strong>Computer Vision:</strong> Empowering machines to interpret and understand visual information from the world.</li>
              <li><strong>AI Applications in Various Industries:</strong> From healthcare to finance, exploring how AI is transforming different sectors.</li>
              <li><strong>AI and the Future of Work:</strong> Examining the impact of AI on employment and the evolving job market.</li>
            </ul>
            <p>
              Join me as we unravel the complexities of these AI subfields, discuss their practical applications, 
              and contemplate the ethical implications of this transformative technology. Whether you're an AI enthusiast, 
              a professional in the field, or simply curious about the future of technology, our blog aims to provide 
              insightful, accessible, and thought-provoking content on the world of Artificial Intelligence.
            </p>
            <p>
              Stay tuned for regular updates, in-depth analyses, and exciting discoveries in the realm of AI!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIBlog;
