import React from 'react';

function Experience() {
  return (
    <section id="experience" className="section">
      <div className="container">
        <h2 className="section-title">Professional Experience</h2>
        <div className="timeline">
          <div className="timeline-item left">
            <div className="timeline-content">
              <h3>Top Certified Developer</h3>
              <h4>PeoplePerHour</h4>
              <p>Oct 2020 - Present</p>
              <ul>
                <li>Providing high-quality development services to clients worldwide</li>
                <li>Maintaining top ratings and excellent reputation on the platform</li>
              </ul>
            </div>
          </div>
          <div className="timeline-item right">
            <div className="timeline-content">
              <h3>Chief Executive Officer</h3>
              <h4>DE VANZARE SRL</h4>
              <p>Aug 2020 - Jan 2024</p>
              <ul>
                <li>Led and developed the online classifieds platform </li>
                <li>Managed daily operations and business growth strategy</li>
              </ul>
            </div>
          </div>
          <div className="timeline-item left">
            <div className="timeline-content">
              <h3>System Administrator</h3>
              <h4>STONE PLAZA SRL</h4>
              <p>Jun 2020 - Jan 2024</p>
              <ul>
                <li>Administered and maintained IT systems for STONE PLAZA</li>
                <li>Ensured optimal functioning of technological infrastructure</li>
              </ul>
            </div>
          </div>
          <div className="timeline-item right">
            <div className="timeline-content">
              <h3>Full Stack Engineer</h3>
              <h4>SEKO Scheepselektrotechniek</h4>
              <p>Oct 2021 - Oct 2022</p>
              <ul>
                <li>Developed a PLC control iOS app using Xamarin.iOS and OPC UA</li>
                <li>Implemented IoT solutions for the maritime industry</li>
              </ul>
            </div>
          </div>
          <div className="timeline-item left">
            <div className="timeline-content">
              <h3>Mobile Application Developer</h3>
              <h4>Red Sentence</h4>
              <p>Jul 2020 - Dec 2021</p>
              <ul>
                <li>Developed websites using HTML, JavaScript, AJAX, and JSON</li>
                <li>Created web applications with WordPress and Laravel</li>
                <li>Performed back-end development using PHP</li>
              </ul>
            </div>
          </div>
          <div className="timeline-item right">
            <div className="timeline-content">
              <h3>Full Stack Engineer</h3>
              <h4>Tinint</h4>
              <p>Jan 2020 - Nov 2021</p>
              <ul>
                <li>Created custom native mobile applications</li>
                <li>Managed the entire lifecycle of Android apps</li>
                <li>Conducted advanced testing and debugging of applications</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;