// src/components/Hero.js
import React from 'react';

function Hero() {
  return (
    <section className="hero">
      
      <div className="hero-content">
        <h1>Ene Florian</h1>
        <p>Full Stack Developer <a href="https://www.peopleperhour.com/freelancer/technology-programming/florian-ene-full-stack-web-mobile-developer-qaxxzjy" className="btn btn-icon" target="_blank" rel="noopener noreferrer" style={{ padding: 0, width: '30px', height: '30px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
              <path d="M15,2.65996344 C8.19926874,2.65996344 2.65996344,8.17184644 2.65996344,15 C2.65996344,21.8281536 8.17184644,27.3400366 15,27.3400366 C21.8281536,27.3400366 27.3400366,21.8281536 27.3400366,15 C27.3400366,8.19926874 21.8281536,2.65996344 15,2.65996344 Z M30,15 C30,23.2815356 23.2815356,30 15,30 C6.71846435,30 -1.00613962e-16,23.2815356 -1.00613962e-16,15 C-3.99755767e-15,6.71846435 6.71846435,-9.67559366e-14 15,-9.67559366e-14 C23.2815356,-9.67559366e-14 30,6.71846435 30,15 Z M8.58318099,8.47349177 C10.1462523,8.47349177 11.4076782,9.73491773 11.4076782,11.297989 C11.4076782,12.8610603 10.1462523,14.1224863 8.58318099,14.1224863 C6.99268739,14.095064 5.75868373,12.833638 5.75868373,11.297989 C5.75868373,9.73491773 7.02010969,8.47349177 8.58318099,8.47349177 Z M21.2522852,8.47349177 C22.8153565,8.47349177 24.0767824,9.73491773 24.0767824,11.297989 C24.0767824,12.8610603 22.8153565,14.1224863 21.2522852,14.1224863 C19.6892139,14.1224863 18.4277879,12.833638 18.4277879,11.297989 C18.4277879,9.73491773 19.6892139,8.47349177 21.2522852,8.47349177 Z" fill="currentColor"/>
            </svg>
          </a></p>
        <p>I can build anything you want</p>
        <div className="hero-buttons">
          <a href="https://wa.me/+40769273635" className="btn" target="_blank" rel="noopener noreferrer">Get in touch with me</a>
          
        </div>
      </div>
    </section>
  );
}

export default Hero;