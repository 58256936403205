import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faTwitter, faFacebook, faInstagram, faWhatsapp, faSkype } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <section id="contact" className="section">
      <div className="container">
        <h2 className="section-title">Contact</h2>
        <div className="card contact-card">
          <div className="contact-info">
            <p>I am open to collaboration opportunities in software development. Feel free to contact me for discussions or project proposals.</p>
          </div>
          <div className="social-links">
            <a href="mailto:eneflorian@de-vanzare.ro" className="social-link">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a href="https://wa.me/+40769273635" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a href="skype:live:steadydevelop?chat" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSkype} />
            </a>
            <a href="https://www.peopleperhour.com/freelancer/technology-programming/florian-ene-full-stack-web-mobile-developer-qaxxzjy" className="social-link" target="_blank" rel="noopener noreferrer">
              <svg width="24" height="24" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path d="M15,2.66C8.2,2.66,2.66,8.2,2.66,15c0,6.8,5.54,12.34,12.34,12.34c6.8,0,12.34-5.54,12.34-12.34 C27.34,8.2,21.8,2.66,15,2.66z M8.58,11.3c0-1.56,1.26-2.82,2.82-2.82c1.56,0,2.82,1.26,2.82,2.82c0,1.56-1.26,2.82-2.82,2.82 C9.84,14.12,8.58,12.86,8.58,11.3z M21.25,11.3c0,1.56-1.26,2.82-2.82,2.82c-1.56,0-2.82-1.26-2.82-2.82 c0-1.56,1.26-2.82,2.82-2.82C19.99,8.48,21.25,9.74,21.25,11.3z" fill="currentColor"/>
              </svg>
            </a>
            <a href="https://www.linkedin.com/in/florian-ene-747994a6/" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://github.com/fantasticDev2" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href="https://x.com/eneflorian17" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.facebook.com/iliiiiiiiiie" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/ene.flo" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
