import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from '../components/Hero';
import About from '../components/About';
import Developer from '../components/Developer';
import Experience from '../components/Experience';
import Contact from '../components/Contact';

function Home() {
  const location = useLocation();
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const developerRef = useRef(null);
  const experienceRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const refs = {
        home: homeRef,
        about: aboutRef,
        developer: developerRef,
        experience: experienceRef,
        contact: contactRef
      };
      const targetRef = refs[location.state.scrollTo];
      if (targetRef && targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="home-container">
      <section ref={homeRef} id="home" className="hero-section"><Hero /></section>
      <section ref={aboutRef} id="about"><About /></section>
      <section ref={developerRef} id="developer"><Developer /></section>
      <section ref={experienceRef} id="experience"><Experience /></section>
      <section ref={contactRef} id="contact"><Contact /></section>
    </div>
  );
}

export default Home;
