import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className="section">
      <div className="container">
        <h2 className="section-title">About Me</h2>
        
        <div className="card">
          <div className="about-content">
            <div className="about-image-container">
              <img 
                src="/main2.jpg" 
                alt="Imagine despre mine" 
                className="about-img" 
              />
            </div>
            <div className="about-text">
              <p>Innovative and self-motivated developer with 9 years of professional experience in software development and project management. I will meet your expectations, having so much experience in the following skills.</p>
              <p>As a Full Stack developer, I have worked on a diverse range of projects, from mobile applications to complex web platforms. My experience includes working with technologies such as React, Node.js, PHP, Laravel, WordPress, Xamarin.iOS, OPC UA, and various databases. I am particularly skilled in developing scalable and efficient solutions for various industries, including IoT solutions for the maritime sector and custom native mobile applications.</p>
              <p>I also work as a Top Certified Developer on PeoplePerHour, where I offer high-quality development services to clients worldwide.</p>
              <p>My goal is to continue to grow, pushing the boundaries of what is possible in software development.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
